import React, { Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';
import { activeConfig } from '../config/clientConfig';
import FeatureFlag from '../components/FeatureFlag/FeatureFlag';

// lazy load all the views

// auth
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('pages/account/ResetPassword'));
const ResetPasswordSuccess = React.lazy(() => import('pages/account/ResetPasswordSuccess'));
const FirstTimeSetup = React.lazy(() => import('pages/account/FirstLoginWizard'));
const VerifyEmail = React.lazy(() => import('pages/account/EmailConfirm2'));

const Login2 = React.lazy(() => import('pages/account/Login2'));
const Logout2 = React.lazy(() => import('pages/account/Logout2'));
const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('pages/account/ForgetPassword2'));
const TermsAndConditions = React.lazy(() => import('pages/account/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('pages/account/PrivacyPolicy'));

// apps
const CalendarApp = React.lazy(() => import('pages/apps/Calendar'));
const sistemsAlertDashboard = React.lazy(() => import('pages/apps/SistemsAlerts/'));
const transfusionAlertPanel = React.lazy(() => import('pages/transfusions/transfusionAlertPanel'));

// -crm
const CRMOrderList = React.lazy(() => import('pages/apps/CRM/OrderList'));

// - file
//const FileManager = React.lazy(() => import('pages/apps/FileManager'));

// pages
const Profile = React.lazy(() => import('pages/profile'));

const Pathologies = React.lazy(() => import('pages/Pathologies'));
const Medicines = React.lazy(() => import('pages/medicines'));

const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

const MedicalHistoryWizard = React.lazy(() => import('pages/medicalHistoryWizard'))

// - other
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

const PreLoader = React.lazy(() => import('pages/other/PreLoader/'));


const Landing = React.lazy(() => import('pages/landing/'));

// myHealth
const MyHealthOverview = React.lazy(() => import('pages/myHealth/Overview'));
const MyHealthDetailed = React.lazy(() => import('pages/myHealth/Detailed'));
const AlertDashboard = React.lazy(() => import('pages/myHealth/Alerts'));
// const MyHealthTemperature = React.lazy(() => import('pages/myHealth/Temperature'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();
    const authProtectedRoutes: RouteObject[] = [];
    const authPublicRoutes: RouteObject[] = [];

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    if (activeConfig.routes.myHealth) {
        authProtectedRoutes.push({
            path: 'myHealth',
            children: [
                {
                    path: 'alerts/',
                    element:
                        <FeatureFlag name="alerts">
                            <LoadComponent component={AlertDashboard} />
                        </FeatureFlag>
                },
                {
                    path: 'overview',
                    element: <FeatureFlag name="overview">
                        <LoadComponent component={MyHealthOverview} />
                    </FeatureFlag>

                },
                {
                    path: 'detailed',
                    element: <FeatureFlag name="detailed">
                        <LoadComponent component={MyHealthDetailed} />
                    </FeatureFlag>

                },
            ],
        });
    }

    if (activeConfig.routes.apps) {
        authProtectedRoutes.push({
            path: 'apps',
            children: [
                {
                    path: 'calendar',
                    element: <FeatureFlag name="calendar">
                        <LoadComponent component={CalendarApp} />
                    </FeatureFlag>
                },
                {
                    path: 'rpm',
                    children: [
                        {
                            path: 'doctors',
                            element: <FeatureFlag name="doctors">
                                <LoadComponent component={CRMOrderList} />
                            </FeatureFlag>
                        },
                    ],
                },
                {
                    path: 'notifications',
                    children: [
                        {
                            path: 'systemNotifications/',
                            element: <FeatureFlag name="systemNotifications">
                                <LoadComponent component={sistemsAlertDashboard} />
                            </FeatureFlag>
                        },
                    ],
                },
                {
                    path: 'transfusions',
                    children: [
                        {
                            path: 'alertPanel',
                            element: <FeatureFlag name="alertPanel">
                                <LoadComponent component={transfusionAlertPanel} />
                            </FeatureFlag>
                        },
                    ],
                },

            ],
        });
    }
    /* 
                       path: 'analytics',
                        element: (
                            <FeatureFlag name="analytics">
                                <LoadComponent component={AnalyticsDashboard} />
                            </FeatureFlag>
                        ),
    */
    if (activeConfig.routes.pages) {
        authProtectedRoutes.push({
            path: 'pages',
            children: [
                {
                    path: 'profile',
                    element: <FeatureFlag name="profile">
                        <LoadComponent component={Profile} />
                    </FeatureFlag>
                },
                {
                    path: 'medicalHistory',
                    element: <FeatureFlag name="medicalHistory">
                        <LoadComponent component={MedicalHistoryWizard} />
                    </FeatureFlag>
                },
                {
                    path: 'pathologies',
                    element: <FeatureFlag name="pathologies">
                        <LoadComponent component={Pathologies} />
                    </FeatureFlag>
                },
                {
                    path: 'medicines',
                    element: <FeatureFlag name="medicines">
                        <LoadComponent component={Medicines} />
                    </FeatureFlag>
                },
                {
                    path: 'error-404-alt',
                    element: <LoadComponent component={ErrorPageNotFoundAlt} />
                },
                {
                    path: 'analytics',
                    element: (
                        <FeatureFlag name="analytics">
                            <LoadComponent component={ErrorPageNotFoundAlt} />
                        </FeatureFlag>
                    )
                }

            ],
        });
    }

    authPublicRoutes.push({
        path: 'account',
        children: [
            {
                path: 'first-time-setup/:uuid',
                element: <LoadComponent component={FirstTimeSetup} />
            },
            {
                path: 'verify-email',
                element: <LoadComponent component={VerifyEmail} />
            },
            { path: 'login', element: <LoadComponent component={Login2} /> },
            {
                path: 'register2', element: <FeatureFlag name="register">
                    <LoadComponent component={Register} />
                </FeatureFlag>
            },
            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            { path: 'logout', element: <LoadComponent component={Logout} /> },
            { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
            { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
            { path: 'logout2', element: <LoadComponent component={Logout2} /> },
            { path: 'reset-password', element: <LoadComponent component={ResetPassword} /> },
            { path: 'reset-password-success', element: <LoadComponent component={ResetPasswordSuccess} /> },
            {
                path: 'error-404',
                element: <LoadComponent component={ErrorPageNotFound} />
            },
            {
                path: 'error-500',
                element: <LoadComponent component={ServerError} />
            },
            {
                path: 'maintenance',
                element: <LoadComponent component={Maintenance} />
            },
            {
                path: 'terms', element: <FeatureFlag name="termsAndConditions">
                    <LoadComponent component={TermsAndConditions} />
                </FeatureFlag>
            },
            {
                path: 'privacy', element: <FeatureFlag name="termsAndConditions">
                    <LoadComponent component={PrivacyPolicy} />
                </FeatureFlag>
            },
        ],
    });

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: authPublicRoutes,
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: authProtectedRoutes,
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
            ],
        },
    ]);
};

export { AllRoutes };