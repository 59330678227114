import { UpdateMedicalHistory } from 'pages/profile/type';
import { APICore } from './apiCore';
import { activeConfig } from 'config/clientConfig';

const BASE_URL = `${activeConfig.api.DOMAIN}`;
const api = new APICore();

type UpdateUserData = {
    uuid: string,
    uuid_edit: string
    permission: number
    email: string
    user_name: string
    type_user_id: number
    password: string
    passwordOld: string
    uuid_clinic: string
    reference_id: string
    name: string
    last_name: string
    birth_date: string
    phone: string
    emergency_phone: string
    birth_address: string
    address: string
    height: number
    Weight: number
    gender_id: number
    photo: string
    blood_type: number
    about_user: string
    unit_temperature_id: number
    unit_weight_id: number
    unit_height_id: number
    emergencyName: string
    emergencyCellphone: string
    emergencyRelationship: string
};
type PathologyUserData = {
    uuid: string,
    uuid_edit: string
    DiagnosticDate: string
    DischargeDate: string
    Comment: string
    pathology_type: number
};
type UpdatePathologyUserData = {
    uuid: string,
    uuid_edit: string
    DiagnosticDate: string
    DischargeDate: string
    Comment: string
    PathologyUserId: number
    pathology_type: number

};
type MedicineUserData = {
    uuid: string,
    uuid_edit: string
    StartTreatmentDate: string
    EndTreatmentDate: string

    Dose: string
    TreatmentDays: string
    TimesDay: string

    Medicine_type: number

};
type UpdateMedicineUserData = {
    uuid: string,
    uuid_edit: string
    StartTreatmentDate: string
    // EndTreatmentDate:string 

    Dose: string
    TreatmentDays: string
    TimesDay: string

    MedicineUserId: number
    Medicine_type: number

};

function setEmail(params: { uuid: string, email: string }) {
    const baseUrl = `/${BASE_URL}/set-email/`;
    return api.create(`${baseUrl}`, params);
}

function verifyEmail(params: { token: string }) {
    const baseUrl = `/${BASE_URL}/verify-email/`;
    return api.create(`${baseUrl}`, params);
}


function verifyUserIdentity(params: { uuid: string, answers: { question1: string, question2: string } }) {
    const baseUrl = `/${BASE_URL}/verify-user-identity-jd/`;
    return api.create(`${baseUrl}`, params);
}

function setSecurityQuestion(params: { uuid: string, question: string, answer: string }) {
    const baseUrl = `/${BASE_URL}/set-security-question/`;
    return api.create(`${baseUrl}`, params);
}

function changePassword(params: { uuid: string, newPassword: string }) {
    const baseUrl = `/${BASE_URL}/change-password/`;
    return api.create(`${baseUrl}`, params);
}

function getUserData(params: { uuid: string }) {
    const baseUrl = `/${BASE_URL}/getUserInfo/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserPreferences(params: { uuid: string }) {
    const baseUrl = `/${BASE_URL}/getUserPreferences/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}


function getUserMedicalRecords(params: { uuid: string }) {
    const baseUrl = `/${BASE_URL}/getUserMedicalHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function updateUser(user: UpdateUserData) {
    const baseUrl = `/${BASE_URL}/updateUser/`;
    const payload = user;
    return api.create(`${baseUrl}`, payload);
}

function getUserParameters(params: { uuid: string, parameterType: string }) {
    const baseUrl = `/${BASE_URL}/getUserParameters/`;
    const payload = {
        uuid: params.uuid,
        parameterType: params.parameterType,
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

function getParameterState(params: { uuid: string, parameterType: string }) {
    const baseUrl = `/${BASE_URL}/getParameterState/`;
    const payload = {
        uuid: params.uuid,
        parameterType: params.parameterType,
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}


function getUserNotifications(params: { uuid: string, measurement_type: string, notification_level: string, notification_status: string }) {
    const baseUrl = `/${BASE_URL}/getUserNotifications/`;
    const payload = {
        uuid: params.uuid,
        measurement_type: params.measurement_type,
        notification_level: params.notification_level,
        notification_status: params.notification_status
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

function updateUserParameters(params: {
    uuid: string,
    criticalHigh: string,
    normalMax: string,
    normalMin: string,
    criticalLow: string,
    comment: string,
    type: string
}) {
    const baseUrl = `/${BASE_URL}/updateUserParameters/`;
    const payload = {
        uuid: params.uuid,
        criticalHigh: params.criticalHigh,
        normalMax: params.normalMax,
        normalMin: params.normalMin,
        criticalLow: params.criticalLow,
        comment: params.comment,
        type: params.type
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

function addEmergencyAlert(params: {
    uuid: string,
    emergencyType: number,
    comment: string
}) {
    const baseUrl = `/${BASE_URL}/addEmergencyAlert/`;
    const payload = {
        uuid: params.uuid,
        emergencyType: params.emergencyType,
        comment: params.comment
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

function transfusionAlert(params: {
    uuid: string,
    emergencyType: number,
    comment: string
}) {
    const baseUrl = `/${BASE_URL}/addEmergencyAlert/`;
    const payload = {
        uuid: params.uuid,
        emergencyType: params.emergencyType,
        comment: params.comment
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

function updateUserParameters2(params: {
    uuid: string,
    criticalHigh1: string,
    normalMax1: string,
    normalMin1: string,
    criticalLow1: string,
    criticalHigh2: string,
    normalMax2: string,
    normalMin2: string,
    criticalLow2: string,
    comment: string,
    type: string
}) {
    const baseUrl = `/${BASE_URL}/updateUserParameters2/`;
    const payload = {
        uuid: params.uuid,
        criticalHigh1: params.criticalHigh1,
        normalMax1: params.normalMax1,
        normalMin1: params.normalMin1,
        criticalLow1: params.criticalLow1,

        criticalHigh2: params.criticalHigh2,
        normalMax2: params.normalMax2,
        normalMin2: params.normalMin2,
        criticalLow2: params.criticalLow2,

        comment: params.comment,
        type: params.type
    }
    return api.create(`${baseUrl}`, payload);
}

function updateMedicalHistory(userMedicalHistory: UpdateMedicalHistory) {
    const baseUrl = `/${BASE_URL}/updateMedicalHistory/`;
    const payload = userMedicalHistory;
    return api.create(`${baseUrl}`, payload);
}

function PathologyUser(user: PathologyUserData) {
    const baseUrl = `/${BASE_URL}/PathologyUser/`;
    const payload = user;

    return api.create(`${baseUrl}`, payload);
}
function MedicineUser(user: MedicineUserData) {
    const baseUrl = `/${BASE_URL}/MedicineUser/`;
    const payload = user;

    return api.create(`${baseUrl}`, payload);
}
function UpdateTreatmentUser(user: UpdateMedicineUserData) {
    const baseUrl = `/${BASE_URL}/UpdateTreatmentUser/`;
    const payload = user;

    return api.create(`${baseUrl}`, payload);
}
function UpdatePathologyUser(user: UpdatePathologyUserData) {
    const baseUrl = `/${BASE_URL}/UpdatePathologyUser/`;
    const payload = user;

    return api.create(`${baseUrl}`, payload);
}
function DeletePathologyUser(user: UpdatePathologyUserData) {
    const baseUrl = `/${BASE_URL}/DeletePathologyUser/`;
    const payload = user;
    return api.create(`${baseUrl}`, payload);
}
function DeleteMedicineUser(user: UpdateMedicineUserData) {
    const baseUrl = `/${BASE_URL}/DeleteTreatmentUser/`;
    const payload = user;
    return api.create(`${baseUrl}`, payload);
}
function getUserMedicinesSingle(params: { uuid: string, MedicineUserId: number }) {
    const baseUrl = `/${BASE_URL}/GetUserMedicinesSingle/`;
    const payload = {
        uuid: params.uuid,
        MedicineUserId: params.MedicineUserId,
    }
    return api.create(`${baseUrl}`, payload);
}

function getUserMedicines(params: { uuid: string }) {
    const baseUrl = `/${BASE_URL}/GetUserMedicines/`;
    const payload = {
        uuid: params.uuid,
    }
    return api.create(`${baseUrl}`, payload);
}
function getUserPathologies(params: { uuid: string }) {
    const baseUrl = `/${BASE_URL}/GetUserPathologies/`;
    const payload = {
        uuid: params.uuid,
    }
    return api.create(`${baseUrl}`, payload);
}
function getPathologies(params: { uuid: string, category: string }) {
    const baseUrl = `/${BASE_URL}/GetPathologies/`;
    const payload = {
        uuid: params.uuid,
        category: params.category,
    }
    return api.create(`${baseUrl}`, payload);
}
function getUserPathologiesSingle(params: { uuid: string, PathologyUserId: number }) {
    const baseUrl = `/${BASE_URL}/GetUserPathologiesSingle/`;
    const payload = {
        uuid: params.uuid,
        PathologyUserId: params.PathologyUserId,
    }
    return api.create(`${baseUrl}`, payload);
}
function getCategoriesPathology(params: { uuid: string }) {
    const baseUrl = `/${BASE_URL}/GetCategoriesPathology/`;
    const payload = {
        uuid: params.uuid,
    }
    return api.create(`${baseUrl}`, payload);
}
function getMedicines(params: { uuid: string }) {
    const baseUrl = `/${BASE_URL}/GetMedicines/`;
    const payload = {
        uuid: params.uuid,
    }
    return api.create(`${baseUrl}`, payload);
}


function getUsers(params: { TypeUser: number, uuid_edit: string }) {
    const baseUrl = `/${BASE_URL}/getUsers/`;
    const payload = {
        type_user_id: params.TypeUser,
        uuid_edit: params.uuid_edit
    }
    return api.create(`${baseUrl}`, payload);
}
function getMyDoctorUsers(params: { TypeUser: number, uuid_edit: string }) {
    const baseUrl = `/${BASE_URL}/getMyDoctorUsers/`;
    const payload = {
        type_user_id: params.TypeUser,
        uuid_edit: params.uuid_edit
    }
    return api.create(`${baseUrl}`, payload);
}
function UpdateStatusNotification(params: { uuid_user: string, status: string, nusId: string, read_all: string, comment: string }) {
    const baseUrl = `/${BASE_URL}/UpdateStatusNotification/`;
    const payload = {
        uuid_edit: params.uuid_user,
        status: params.status,
        nusId: params.nusId,
        read_all: params.read_all,
        comment: params.comment

    }
    // console.log(payload);
    return api.create(`${baseUrl}`, payload);
}
function getUserNotificationsConsult(params: { uuid: string, measurement_type: string, notification_level: string, notification_status: string }) {
    const baseUrl = `/${BASE_URL}/getUserNotificationsConsult/`;
    const payload = {
        uuid: params.uuid,
        measurement_type: params.measurement_type,
        notification_level: params.notification_level,
        notification_status: params.notification_status
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}
function UpdateNotificationGeneric(params: { uuid_user: string, nogId: string, statusCurrent: string, comment: string }) {
    const baseUrl = `/${BASE_URL}/UpdateNotificationGeneric/`;
    const payload = {
        uuid_edit: params.uuid_user,
        nog_id: params.nogId,
        status_measurement: params.statusCurrent,
        comment: params.comment,

    }
    // console.log(payload);
    return api.create(`${baseUrl}`, payload);
}

function GetUserNotificationCalendar(params: { uuid: string, uuid_edit: string }) {
    const baseUrl = `/${BASE_URL}/GetUserNotificationCalendar/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit
    }
    //  console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

export {
    getUserData, updateUser, getUserPathologies
    , getPathologies, PathologyUser, UpdatePathologyUser
    , DeletePathologyUser, getMedicines, getUserMedicines
    , MedicineUser, UpdateTreatmentUser, DeleteMedicineUser
    , getCategoriesPathology, getUserPathologiesSingle
    , getUsers, getUserMedicinesSingle, getMyDoctorUsers, updateMedicalHistory
    , getUserMedicalRecords, getUserNotifications, getUserParameters
    , updateUserParameters, updateUserParameters2, getParameterState, addEmergencyAlert, getUserPreferences
    , UpdateStatusNotification, getUserNotificationsConsult, UpdateNotificationGeneric
    , GetUserNotificationCalendar, setEmail, setSecurityQuestion, changePassword, verifyUserIdentity, verifyEmail, transfusionAlert
};
