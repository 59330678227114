import Routes from 'routes/Routes';
import 'assets/scss/icons.scss';

// Dynamic import based on environment variable
const client = process.env.REACT_APP_CLIENT || 'client1';
switch (client) {
    case 'client1':
        import('assets/scss/Client_1.scss');
        break;
    case 'client2':
        import('assets/scss/Client_2.scss');
        break;
    case 'client3':
        import('assets/scss/Client_3.scss');
        break;
    case 'client4':
        import('assets/scss/Client_4.scss');
        break;
    case 'client6':
        import('assets/scss/Client_6.scss');
        break;
    default:
        import('assets/scss/Client_1.scss'); // Default fallback
}

const App = () => {
    return <Routes />;
};

export default App;